import React                       from 'react';
import { Global, css }             from '@emotion/react';
import { useStaticQuery, graphql } from 'gatsby';

import BaseLayout      from '@interness/web-core/src/components/Layout/Base';
import CookieConsent   from '@interness/web-core/src/components/modules/CookieConsent/CookieConsent';
import GoogleRatingBox from '@interness/web-core/src/components/GoogleRatingBox/GoogleRatingBox';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const DefaultLayout = ({ children, pageContext, location }) => {
  const { theme, allSitePlugin, directusProject } = useStaticQuery(query);
  const showCookieConsent = allSitePlugin.nodes.filter(plugin => plugin.name === 'gatsby-plugin-matomo');
  const globalStyles = css`
    body {
      background-color: ${theme.base_color} !important;
      background: ${theme.base_color} !important;
    }

    a {
      color: ${theme.brand_color};
    }
  `;
  return (
    <BaseLayout pageContext={pageContext}>
      <Global styles={globalStyles}/>
      {showCookieConsent.length >= 1 && <CookieConsent/>}
      {directusProject.google_place_id && <GoogleRatingBox placeId={directusProject.google_place_id}/>}
      <div style={{ position: 'relative', minHeight: '100vh' }}>
        <Header location={location} lang={pageContext.lang} config={theme.config.components.header}/>
        <main style={{ paddingBottom: '50px' }}>
          {children}
        </main>
        <Footer/>
      </div>
    </BaseLayout>
  )
};

const query = graphql`
    query {
        directusProject {
            google_place_id
        }
        allSitePlugin {
            nodes {
                name
            }
        }
        theme {
            ...CoreTheme
            config {
                components {
                    header {
                        fullHeight
                        collapsedHeight
                        smallScreenThreshold
                        accentBorder
                    }
                    footer {
                        backgroundColor
                        fontColor
                        subFooterBackgroundColor
                        subFooterFontColor
                        subFooter
                    }
                }
                templates {
                    brands {
                        listStyle
                        showTypeDescription
                    }
                }
            }
        }
    }
`;

export default DefaultLayout;
